// src/pages/Features.jsx
import React from 'react';

// Определение стилей
const featuresStyle = {
    padding: '20px',
    borderRadius: '8px', // Скругленные углы
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Легкая тень для улучшения визуального восприятия
};

const featureItemStyle = {
    backgroundColor: '#555', // Белый фон для каждой карточки
    padding: '15px 20px',
    marginBottom: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Более выраженная тень
    width: '100%',
    maxWidth: '600px', // Ограничивает ширину карточек
    textAlign: 'center',
    color: '#fff',
    userSelect: 'none'
};

const Features = () => (
    <main style={featuresStyle}>
        <h2 className='home-title'>Функции приложения MatStart</h2>
        <ul className='home-container'>
            <li style={featureItemStyle}>Интерактивные уроки по математике</li>
            <li style={featureItemStyle}>Задания на развитие логики и мышления</li>
            <li style={featureItemStyle}>Мониторинг прогресса и достижения</li>
            <li style={featureItemStyle}>Поддержка всех уровней сложности</li>
        </ul>
    </main>
);

export default Features;