import React from 'react';
import DownloadButton from '../components/DownloadButton'; // Убедитесь, что путь указан правильно

const Home = () => (
    <main className="home-container">
        <h1 className="home-title">Добро пожаловать на MatStart!</h1>
        <p className="home-description">
            MatStart — это лучшее приложение для начала изучения математики. Узнайте больше о его функциях и начните
            свой путь к математическим вершинам!
        </p>
        <p className="home-description">
            Наше приложение предлагает уникальную возможность: решайте математические задачи, зарабатывайте баллы и
            обменивайте их на реальные деньги! Обучение и игра идут рука об руку, делая процесс весёлым и полезным.
        </p>
        <p className="home-description">
            Важно отметить, что для использования нашего приложения вам не нужно совершать покупки или вносить деньги.
            Всё обучение и возможности доступны бесплатно!
        </p>
        <DownloadButton/> {/* Добавляем кнопку скачивания */}
        <div style={{height: "100px"}}/>
    </main>
);

export default Home;