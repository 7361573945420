import React from 'react';

const Footer = () => (
    <footer style={footerStyle}>
        <p style={footerTextStyle}>© 2024 MatStart. Все права защищены.</p>
        <p style={footerTextStyle}>
            <a href="/terms-of-service.html" style={linkStyle}>Правила использования</a>
            <span style={separatorStyle}> | </span>
            <a href="/privacy-policy.html" style={linkStyle}>Политика конфиденциальности</a>
            <span style={separatorStyle}> | </span>
            Контакты: <a href="mailto:pro100yrik22@gmail.com" style={linkStyle}>pro100yrik22@gmail.com</a>
        </p>
    </footer>
);

const footerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#333',
    textAlign: 'center',
    padding: '10px',
    boxSizing: 'border-box', // Учитывает padding в ширину
};

const footerTextStyle = {
    color: '#fff',
    margin: 0,
    fontSize: '0.9rem', // Уменьшенный размер шрифта для футера
};

const linkStyle = {
    color: '#fff',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 0.3s',
};

const separatorStyle = {
    margin: '0 10px', // Горизонтальные отступы между элементами
    color: '#fff',    // Цвет разделителя
};

export default Footer;