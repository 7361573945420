// src/pages/Reviews.jsx
import React from 'react';

const reviews = [
    { text: "Отличное приложение для изучения математики!", author: "Иван И." },
    { text: "MatStart помог мне улучшить мои знания по математике!", author: "Ольга П." },
    { text: "Очень удобный интерфейс и множество полезных функций.", author: "Алексей С." },
    { text: "Приложение идеально подходит для начинающих.", author: "Мария К." },
    { text: "Качественные уроки и отличная поддержка!", author: "Дмитрий В." },
    { text: "Я рекомендую MatStart всем, кто хочет изучать математику.", author: "Елена Л." },
    { text: "Приложение помогает мне учиться в любое время.", author: "Сергей М." },
    { text: "Интуитивно понятный интерфейс и много практики.", author: "Ольга С." },
    { text: "Приложение помогает освоить сложные темы.", author: "Георгий К." },
    { text: "Очень удобно, что можно учиться где угодно.", author: "София Р." }
];

const Reviews = () => (
    <main className="reviews-container">
        <h2 className="reviews-title">Отзывы пользователей</h2>
        {reviews.map((review, index) => (
            <div className="review-card" key={index}>
                <p className="review-text">“{review.text}”</p>
                <p className="review-author">— {review.author}</p>
            </div>
        ))}
        <div style={{height: "100px"}}/>
    </main>
);

export default Reviews;