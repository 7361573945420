// src/components/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
    <header>
        <nav style={navStyle}>
            <ul style={ulStyle}>
                <li style={listItemStyle}>
                    <Link to="/" style={buttonStyle}>
                        <img src="/logo192.png" alt="MatStart Logo" style={logoStyle} />
                    </Link>
                </li>
                <li style={listItemStyle}><Link to="/" style={buttonStyle}>Главная</Link></li>
                {/*<li style={listItemStyle}><Link to="/features" style={buttonStyle}>Функции</Link></li>*/}
                <li style={listItemStyle}><Link to="/reviews" style={buttonStyle}>Отзывы</Link></li>
            </ul>
        </nav>
    </header>
);

// Определение стилей
const navStyle = {
    backgroundColor: '#333', // Темный фон для навигации
    padding: '10px 20px', // Добавляем отступы по бокам
};

const ulStyle = {
    listStyle: 'none', // Убирает маркеры списка
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center', // Выравнивание по вертикали
};

const listItemStyle = {
    margin: '0 10px',
};

const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    color: 'white', // Белый цвет текста
    backgroundColor: '#555', // Цвет фона кнопки
    border: 'none',
    borderRadius: '5px', // Скругленные углы
    textDecoration: 'none', // Убирает подчеркивание
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '1.5',
    transition: 'background-color 0.3s ease',
    userSelect: 'none'
};

const logoStyle = {
    width: '50px',
    height: '50px',
};

export default Header;